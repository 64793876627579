define('env',['module', 'browserUtils'
], function (module, BrowserUtils) {
  'use strict';

  var Env = {
    config: {
      defaults: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8085/',
        defaultLogo: 'app/styles/css/images/compuzz_logo.png',
        compuzzLogo: 'app/styles/css/images/compuzz.svg',
        notFoundImage: 'app/styles/css/images/404.png',
        catalogueDefaultImage: 'app/styles/css/images/book.png',
        logoToolSubDomain: '',
        //compuzzEnv: 'promoxl',
        editOrderUrl: 'http://localhost:8082/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli',
        gmailClientId: '122809732822-496v9k4pvqkrepc6ag3bvhl9bsuk7lm1.apps.googleusercontent.com',
        gmailAPIKey: 'AIzaSyAHYUdxnK-CmrJSv03VTaiUtav_Nxl5in8',
        editorUrl: 'http://localhost:4200'
      },
      development: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8085/',
        defaultLogo: 'app/styles/css/images/compuzz_logo.png',
        compuzzLogo: 'app/styles/css/images/compuzz.svg',
        notFoundImage: 'app/styles/css/images/404.png',
        catalogueDefaultImage: 'app/styles/css/images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'http://localhost:8082/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli',
        gmailClientId: '872216205306-sao1eokcksf6127esldba7rqhjfbib15.apps.googleusercontent.com',
        gmailAPIKey: 'AIzaSyAAj28bmWED0Mz2Th0653_cW-3g-BhiKcI',
        editorUrl: 'http://localhost:4200'
      },
      devserver: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'fileManagement/',
        //serverUrl: 'https://pxl:pxl@tn.compuzz.com/',
        serverUrl: 'http://localhost:8085/',
        defaultLogo: 'app/styles/css/images/compuzz_logo.png',
        compuzzLogo: 'app/styles/css/images/compuzz.svg',
        notFoundImage: 'app/styles/css/images/404.png',
        catalogueDefaultImage: 'app/styles/css/images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'http://localhost:8082/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli',
        gmailClientId: '872216205306-sao1eokcksf6127esldba7rqhjfbib15.apps.googleusercontent.com',
        gmailAPIKey: 'AIzaSyAAj28bmWED0Mz2Th0653_cW-3g-BhiKcI',
        editorUrl: 'http://localhost:4200',
        downloadUrl: 'prod/dl/',
        webshopUrl: {
          'fr': 'https://www.zaprinta.fr',
          'fr_FR': 'https://www.zaprinta.fr',
          'fr_BE': 'https://www.zaprinta.be',
          'en': 'https://www.zaprinta.com/en',
          'de': 'https://www.zaprinta.com/de',
          'de_DE': 'https://www.zaprinta.com/de',
          'de_AT': 'https://www.zaprinta.at',
          'it': 'https://www.zaprinta.it',
          'it_IT': 'https://www.zaprinta.it',
          'ua': 'https://www.zaprinta.ua',
          'es': 'https://www.zaprinta.es',
          'es_ES': 'https://www.zaprinta.es'
        },
        config: {
          mails: {
            remoteSmtp: true,
            standard: {
              from: {
                def: 'commercial@zaprinta.com'
              },
              allowFromSelection: true
            },
            tracking: {
              from: {
                def: 'commercial@zaprinta.com',
                de: 'verkauf@zaprinta.com',
                at: 'verkauf@zaprinta.com'
              },
              allowFromSelection: true
            },
            invoice: {
              from: {
                def: 'facturation@zaprinta.com'
              },
              allowFromSelection: true
            }
          },
          test: {
            hideBoardContextMenu: true,
            showOnlyOneBoardStatus: false
          },
          webshopApiTokenId: '2tCZOZiHw5KoTmv4xz9BIwx1Nq1jh',
          showProofsheetLink: true,
          plivo: {
            refrechUserStatusTimout: 20000
          },
          priceLocale: 'nl-NL',
          customerDefaultMailLanguage: 'NL',
          customer: {
            profile: {
              showKvk: true,
              showFirstContactVoiceMail: true,
              showFirstContactPhone: true,
              showSiret: true,
              showMainActivity: true,
              showSubActivity: true,
              showExternalNumber: true
            }
          },
          orderDetail: {
            hideValidationDate: false,
            webShopProofSheetUrl: '/proofsheet/{poi}',
            proofSheetPdfUrl: 'https://pxl:pxl@tn.compuzz.com/nl/proofsheet/pdf/{poi}'
          },
          orders: {
            addressFormat: [
              {fields: ['name'], format: '%name'},
              {fields: ['firstName', 'lastName'], format: '%firstName %lastName'},
              {fields: ['addressL1'], format: '%addressL1'},
              {fields: ['addressL2'], format: '%addressL2'},
              {fields: ['houseNumber'], format: '%houseNumber'},
              {fields: ['zip', 'city'], format: '%zip %city'},
              {fields: ['country'], format: '%country'},
              {fields: ['tel'], format: '%tel'}],
            offerProoofsheetLinkText: 'poi',
            showCompuzzVrpOnPimOrder: false,
            showPimIcon: true,
            showValidationButton: true,
            newInvoicePaymentTerms: false,
            deliveryDateShortMonthSize: 4,
            detail: {
              showValidatedPriceStatus: false,
              tracking: {
                showDeliveredButton: true
              },
              contextMenu: {
                showExport: false,
                showProductSheet: false,
                showUploadExport: false,
                showConvertPimToCompuzz: true
              },
              editCustomerInNewTab: false
            },
            newOrderSelectProductType: true,
            newOrderFromPim: false
          },
          order: {
            trackingPageUrl: 'https://zaprinta.aftership.com/',
            attachments: {
              showCustomerAttachments: true
            }
          },
          offer: {
            list: {
              showToInvoice: true
            }
          },
          board: {
            emails: {
              proofsheet: {
                pdfAttachment: true
              }
            },
            header: {
              hideQuantity: false,
              showKvk: false
            },
            card: {
              showColorLabelMethod: 'popup',
              mainTitleProperty: ['companyName', 'contactPerson', 'endUserId'],
              messageIcon: 'fromComments__',
              layoutType: 'zaprinta',
              fullDate: false
            }
          },
          contextMenu: {
            hideDeclineStatus: false,
            hideDetails: true
          },
          webshop: {
            url: 'http://localhost:8001',
            imagesUrl: 'https://www.zaprinta.fr/imgs'/*'https://d3ui177wzg1z54.cloudfront.net', */,
            products: {
              showUrlsTab: true,
              showDefaultCategory: true,
              showPageLayout: true,
              showOutdated: true,
              showUploadLogo: true,
              showUnfinished: true,
              showExtraDescription: true,
              showCompositionEditor: true,
              showYoutubeVideo: true,
              showNoindex: true,
              showSeo: false
            },
            categories: {
              showNoindex: true,
              showPageLayout: true,
              showSeoLabel: true,
              showSeoProductDescription: true,
              showSeo: true
            }
          },
          phone: {
            parser: {
              mainCountries: ['NL']
            }
          }
        },
        //compuzzEnv: 'zaprinta'
        compuzzEnv: 'promoxl'
      },
      minified: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8085/',
        defaultLogo: 'images/compuzz_logo.png',
        compuzzLogo: 'images/compuzz.svg',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'http://localhost:8082/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      alpha: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'files/',
        serverUrl: 'https://alphadashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '/alpha/',
        editOrderUrl: 'https://alpha.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      beta: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'files/',
        serverUrl: 'https://betadashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '/beta/',
        editOrderUrl: 'https://beta.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli',
        gmailClientId: '122809732822-496v9k4pvqkrepc6ag3bvhl9bsuk7lm1.apps.googleusercontent.com',
        gmailAPIKey: 'AIzaSyAHYUdxnK-CmrJSv03VTaiUtav_Nxl5in8',
        editorUrl: 'https://betaeditor.compuzz.com'
      },
      betadashboard2: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'files/',
        serverUrl: 'https://betadashboard2.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '/beta/',
        editOrderUrl: 'https://beta.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli',
        gmailClientId: '122809732822-496v9k4pvqkrepc6ag3bvhl9bsuk7lm1.apps.googleusercontent.com',
        gmailAPIKey: 'AIzaSyAHYUdxnK-CmrJSv03VTaiUtav_Nxl5in8',
        editorUrl: 'https://betaeditor.compuzz.com'
      },
      zaprintabeta: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        downloadUrl: 'https://x3wn3zu7te.execute-api.eu-west-1.amazonaws.com/prod/dl/',
        filesPath: 'files/',
        serverUrl: 'https://zaprintabetadashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '/beta/',
        editOrderUrl: 'https://beta.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli',
        gmailClientId: '1044157018081-qibpa2m59bintgd2j1gjk7ha6puogk2t.apps.googleusercontent.com',
        gmailAPIKey: 'AIzaSyC4NZnsWK8joARK8bVTNch-G7rQYJw_vC4',
        editorUrl: 'https://betaeditor.compuzz.com',
        compuzzEnv: 'zaprinta',
        webshopUrl: {
          'fr': 'https://www.zaprinta.fr',
          'fr_FR': 'https://www.zaprinta.fr',
          'fr_BE': 'https://www.zaprinta.be',
          'en': 'https://www.zaprinta.com/en',
          'de': 'https://www.zaprinta.com/de',
          'de_DE': 'https://www.zaprinta.com/de',
          'de_AT': 'https://www.zaprinta.at',
          'it': 'https://www.zaprinta.it',
          'it_IT': 'https://www.zaprinta.it',
          'ua': 'https://www.zaprinta.ua',
          'es': 'https://www.zaprinta.es',
          'es_ES': 'https://www.zaprinta.es'
        },
        config: {
          mails: {
            remoteSmtp:    true,
            standard: {
              from: {
                def: 'commercial@zaprinta.com'
              },
              allowFromSelection: true
            },
            tracking: {
              from: {
                def: 'commercial@zaprinta.com',
                de: 'verkauf@zaprinta.com',
                at: 'verkauf@zaprinta.com'
              },
              allowFromSelection: true
            },
            invoice: {
              from: {
                def: 'facturation@zaprinta.com'
              },
              allowFromSelection: true
            }
          },
          showProofsheetLink: true,
          showFastDelivery: true,
          customerDefaultMailLanguage: 'FR',
          allowEditSupplierProduct: true,
          customer: {
            profile: {
              showKvk: false,
              showFirstContactVoiceMail: true,
              showFirstContactPhone: true,
              showSiret: true,
              showMainActivity: true,
              showSubActivity: true
            }
          },
          orders: {
            addressFormat: [
              {fields: ['name'], format: '%name'},
              {fields: ['firstName', 'lastName'], format: '%firstName %lastName'},
              {fields: ['addressL1'], format: '%addressL1'},
              {fields: ['addressL2'], format: '%addressL2'},
              {fields: ['houseNumber'], format: '%houseNumber'},
              {fields: ['zip', 'city'], format: '%zip %city'},
              {fields: ['country'], format: '%country'},
              {fields: ['tel'], format: '%tel'}],
            showCompuzzVrpOnPimOrder: true,
            showPimIcon: true,
            showValidationButton: true,
            newOrderSelectProductType: true,
            detail: {
              showValidatedPriceStatus: true,
              tracking: {
                showDeliveredButton: false
              },
              contextMenu: {
                showExport: true,
                showProductSheet: true,
                showUploadExport: true,
                showConvertPimToCompuzz: true
              },
              editCustomerInNewTab: false
            }
          },
          order: {
            attachments: {
              showCustomerAttachments: true
            }
          },
          offer: {
            list: {
              showToInvoice: true
            }
          },
          orderDetail: {
            webShopProofSheetUrl: '/proofsheet/notrack/{poi}'
          },
          board: {
            emails: {
              proofsheet: {
                pdfAttachment: true
              }
            },
            card: {
              showColorLabelMethod: 'popup',
              mainTitleProperty: ['companyName', 'contactPerson', 'endUserId'],
              layoutType: 'zaprinta'
            }
          },
          webshop: {
            url: 'https://www.zaprinta.fr',
            imagesUrl: 'https://www.zaprinta.fr/imgs',
            products: {
              showUrlsTab: true,
              showDefaultCategory: true,
              showPageLayout: true,
              showOutdated: true,
              showUploadLogo: true,
              showUnfinished: true,
              showExtraDescription: true,
              showCompositionEditor: true,
              showYoutubeVideo: true,
              showNoindex: false,
              showSeo: false
            },
            categories: {
              showNoindex: false,
              showPageLayout: false,
              showSeoLabel: false,
              showSeoProductDescription: false,
              showSeo: true
            }
          }
        }
      },
      igobeta: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'files/',
        serverUrl: 'https://igobetadashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '/beta/',
        editOrderUrl: 'https://beta.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzProProtocol: '',
        compuzzEnv: 'igo',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      igo: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://igodashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://igodashboard.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzProProtocol: 'cpzigo',
        compuzzEnv: 'igo',
        nodeUrl: 'https://compulogo.compuzz.com/',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      adtv2: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://dashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      prod2: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://dashboard2.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        gmailClientId: '',
        gmailAPIKey: '',
        editorUrl: 'https://editor.compuzz.com'
      },
      zaprinta: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        downloadUrl: 'https://x3wn3zu7te.execute-api.eu-west-1.amazonaws.com/prod/dl/',
        filesPath: 'files/',
        serverUrl: 'https://zaprinta.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzEnv: 'zaprinta',
        gmailClientId: '1044157018081-qibpa2m59bintgd2j1gjk7ha6puogk2t.apps.googleusercontent.com',
        gmailAPIKey: 'AIzaSyC4NZnsWK8joARK8bVTNch-G7rQYJw_vC4',
        nodeUrl: 'https://ncf.compuzz.com/',
        editorUrl: 'https://editor.compuzz.com',
        webshopUrl: {
          'fr': 'https://www.zaprinta.fr',
          'fr_FR': 'https://www.zaprinta.fr',
          'fr_BE': 'https://www.zaprinta.be',
          'en': 'https://www.zaprinta.com/en',
          'en_US': 'https://www.zaprinta.com/en',
          'de': 'https://www.zaprinta.com/de',
          'de_DE': 'https://www.zaprinta.com/de',
          'de_AT': 'https://www.zaprinta.at',
          'it': 'https://www.zaprinta.it',
          'it_IT': 'https://www.zaprinta.it',
          'ua': 'https://www.zaprinta.ua',
          'es': 'https://www.zaprinta.es',
          'es_ES': 'https://www.zaprinta.es',
          'da': 'https://www.zaprinta.dk',
          'da_DK': 'https://www.zaprinta.dk'
        },
        config: {
          mails: {
            remoteSmtp:    true,
            standard: {
              from: {
                def: 'commercial@zaprinta.com',
                de: 'verkauf@zaprinta.com',
                at: 'verkauf@zaprinta.com',
                it: 'commerciale@zaprinta.com',
                es: 'comercial@zaprinta.com'
              },
              allowFromSelection: true
            },
            tracking: {
              from: {
                def: 'commercial@zaprinta.com',
                de: 'verkauf@zaprinta.com',
                at: 'verkauf@zaprinta.com',
                it: 'commerciale@zaprinta.com',
                es: 'comercial@zaprinta.com'
              },
              allowFromSelection: true
            },
            invoice: {
              from: {
                def: 'facturation@zaprinta.com'
              },
              allowFromSelection: true
            }
          },
          webshopApiTokenId: '2tCZOZiHw5KoTmv4xz9BIwx1Nq1jh',
          mollieOrg: 'org_3592394',
          showProofsheetLink: true,
          showFastDelivery: true,
          customerDefaultMailLanguage: 'FR',
          allowEditSupplierProduct: true,
          customer: {
            profile: {
              showKvk: false,
              showFirstContactVoiceMail: true,
              showFirstContactPhone: true,
              showSiret: true,
              showMainActivity: true,
              showSubActivity: true
            }
          },
          orders: {
            addressFormat: [
              {fields: ['name'], format: '%name'},
              {fields: ['firstName', 'lastName'], format: '%firstName %lastName'},
              {fields: ['addressL1'], format: '%addressL1'},
              {fields: ['addressL2'], format: '%addressL2'},
              {fields: ['houseNumber'], format: '%houseNumber'},
              {fields: ['zip', 'city'], format: '%zip %city'},
              {fields: ['country'], format: '%country'},
              {fields: ['tel'], format: '%tel'}],
            offerProoofsheetLinkText: 'poi',
            showCompuzzVrpOnPimOrder: true,
            showPimIcon: true,
            showValidationButton: true,
            newOrderSelectProductType: true,
            deliveryDateShortMonthSize: 4,
            detail: {
              showValidatedPriceStatus: true,
              tracking: {
                showDeliveredButton: false
              },
              contextMenu: {
                showExport: true,
                showProductSheet: true,
                showUploadExport: true,
                showConvertPimToCompuzz: true
              },
              editCustomerInNewTab: false
            }
          },
          order: {
            trackingPageUrl: 'https://zaprinta.aftership.com/',
            attachments: {
              showCustomerAttachments: true
            }
          },
          offer: {
            list: {
              showToInvoice: true
            }
          },
          orderDetail: {
            webShopProofSheetUrl: '/proofsheet/notrack/{poi}'
          },
          board: {
            emails: {
              proofsheet: {
                pdfAttachment: true
              }
            },
            card: {
              showColorLabelMethod: 'popup',
              mainTitleProperty: ['companyName', 'contactPerson', 'endUserId'],
              layoutType: 'zaprinta',
              fullDate: false
            }
          },
          webshop: {
            url: 'https://www.zaprinta.fr',
            imagesUrl: 'https://www.zaprinta.fr/imgs',
            products: {
              showUrlsTab: true,
              showDefaultCategory: true,
              showPageLayout: true,
              showOutdated: true,
              showUploadLogo: true,
              showUnfinished: true,
              showExtraDescription: true,
              showCompositionEditor: true,
              showYoutubeVideo: true,
              showNoindex: true,
              showSeo: false
            },
            categories: {
              showNoindex: true,
              showPageLayout: true,
              showSeoLabel: true,
              showSeoProductDescription: true,
              showSeo: true
            }
          },
          phone: {
            parser: {
              mainCountries: ['BE', 'FR', 'DE', 'NL', 'AT']
            }
          }
        }
      },
      lapierre: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://lapierre.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzEnv: 'lapierre',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      prestigelogo: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://prestigelogo.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzEnv: 'prestigelogo',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      promoxlbeta: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        downloadUrl: 'https://x3wn3zu7te.execute-api.eu-west-1.amazonaws.com/prod/dl/',
        filesPath: 'files/',
        serverUrl: 'https://promoxlbetadashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzEnv: 'promoxl',
        gmailClientId: '122809732822-496v9k4pvqkrepc6ag3bvhl9bsuk7lm1.apps.googleusercontent.com',
        gmailAPIKey: 'AIzaSyAHYUdxnK-CmrJSv03VTaiUtav_Nxl5in8',
        webshopUrl: 'https://www.promo-xl.com/nl',
        config: {
          mollieOrg: 'org_8361271',
          showProofsheetLink: false,
          priceLocale: 'nl-NL',
          customerDefaultMailLanguage: 'NL',
          orderDetail: {
            hideValidationDate: true,
            webShopProofSheetUrl: '/proofsheet/{poi}'
          },
          orders: {
            showPimIcon: false,
            showValidationButton: true,
            detail: {
              showValidatedPriceStatus: false,
              tracking: {
                showDeliveredButton: true
              },
              contextMenu: {
                showExport: false,
                showProductSheet: false,
                showUploadExport: false,
                showConvertPimToCompuzz: false
              },
              editCustomerInNewTab: true
            },
            newOrderFromPim: true
          },
          order: {
            attachments: {
              showCustomerAttachments: true
            }
          },
          offer: {
            list: {
              showToInvoice: false
            }
          },
          customer: {
            profile: {
              showKvk: true,
              showFirstContactVoiceMail: false,
              showFirstContactPhone: false,
              showSiret: false,
              showMainActivity: false,
              showSubActivity: false
            },
            list: {
              displayNameInsteadOfCode: true
            }
          },
          board: {
            header: {
              hideQuantity: true,
              showKvk: true
            },
            card: {
              showColorLabelMethod: 'popup',
              mainTitleProperty: ['companyName', 'contactPerson', 'endUserId'],
              messageIcon: 'fromComments'
            },
            emails: {
              proofsheet: {
                pdfAttachment: true
              }
            }
          },
          contextMenu: {
            hideDeclineStatus: true,
            hideDetails: true
          },
          hidePopupSameCustomer: true
        }
      },
      promoxl: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        downloadUrl: 'https://x3wn3zu7te.execute-api.eu-west-1.amazonaws.com/prod/dl/',
        filesPath: 'files/',
        serverUrl: 'https://promoxl.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzEnv: 'promoxl',
        gmailClientId: '122809732822-496v9k4pvqkrepc6ag3bvhl9bsuk7lm1.apps.googleusercontent.com',
        gmailAPIKey: 'AIzaSyAHYUdxnK-CmrJSv03VTaiUtav_Nxl5in8',
        webshopUrl: 'https://www.promo-xl.com/nl',
        config: {
          mails: {
            remoteSmtp:    true,
            standard: {
              from: {
                def: 'info@promo-xl.com'
              },
              allowFromSelection: true
            },
            tracking: {
              from: {
                def: 'info@promo-xl.com'
              },
              allowFromSelection: true
            },
            invoice: {
              from: {
                def: 'info@promo-xl.com'
              },
              bcc: 'info@promo-xl.com',
              allowFromSelection: true
            }
          },
          mollieOrg: 'org_8361271',
          showProofsheetLink: false,
          priceLocale: 'nl-NL',
          customerDefaultMailLanguage: 'NL',
          orderDetail: {
            hideValidationDate: true,
            webShopProofSheetUrl: '/proofsheet/{poi}'
          },
          orders: {
            addressFormat: [
              {fields: ['name'], format: '%name'},
              {fields: ['firstName', 'lastName'], format: '%firstName %lastName'},
              {fields: ['addressL1'], format: '%addressL1'},
              {fields: ['addressL2'], format: '%addressL2'},
              {fields: ['houseNumber'], format: '%houseNumber'},
              {fields: ['zip', 'city'], format: '%zip %city'},
              {fields: ['country'], format: '%country'},
              {fields: ['tel'], format: '%tel'}],
            offerProoofsheetLinkText: 'productName',
            showPimIcon: false,
            showValidationButton: true,
            newInvoicePaymentTerms: true,
            detail: {
              showValidatedPriceStatus: false,
              tracking: {
                showDeliveredButton: true
              },
              contextMenu: {
                showExport: false,
                showProductSheet: false,
                showUploadExport: false,
                showConvertPimToCompuzz: false
              },
              editCustomerInNewTab: true
            },
            newOrderFromPim: true
          },
          order: {
            attachments: {
              showCustomerAttachments: true
            }
          },
          offer: {
            list: {
              showToInvoice: false
            }
          },
          customer: {
            profile: {
              showKvk: true,
              showFirstContactVoiceMail: false,
              showFirstContactPhone: false,
              showSiret: false,
              showMainActivity: false,
              showSubActivity: false
            },
            list: {
              displayNameInsteadOfCode: true
            }
          },
          board: {
            header: {
              hideQuantity: true,
              showKvk: true
            },
            card: {
              showColorLabelMethod: 'popup',
              mainTitleProperty: ['companyName', 'contactPerson', 'endUserId'],
              messageIcon: 'fromComments',
              fullDate: true
            },
            emails: {
              proofsheet: {
                pdfAttachment: true
              }
            }
          },
          contextMenu: {
            hideDeclineStatus: true,
            hideDetails: true
          },
          hidePopupSameCustomer: true,
          webshop: {
            url: 'https://www.promo-xl.com/',
            imagesUrl: 'https://d3ui177wzg1z54.cloudfront.net',
            products: {
              showUrlsTab: false,
              showDefaultCategory: false,
              showPageLayout: false,
              showOutdated: false,
              showUploadLogo: false,
              showUnfinished: false,
              showExtraDescription: false,
              showCompositionEditor: false,
              showYoutubeVideo: false,
              showNoindex: false,
              showSeo: true
            },
            categories: {
              showNoindex: false,
              showPageLayout: false,
              showSeoLabel: false,
              showSeoProductDescription: false,
              showSeo: true
            }
          },
          phone: {
            parser: {
              mainCountries: ['NL']
            }
          }
        }
      },
      brandbutler: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://brandbutler.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzEnv: 'brandbutler',
        gmailClientId: '',
        gmailAPIKey: ''
      }
    },

    isProduction: function () {
      return this.getEnvironment() === 'production';
    },

    getEnvironment: function () {
      switch (BrowserUtils.getHostName()) {
        case 'localhost':
        case '127.0.0.1':
          return 'localhost';
        case 'alphadashboard.compuzz.com':
          return 'alpha';
        case 'betadashboard.compuzz.com':
          return 'beta';
        case 'dashboard.compuzz.com':
          return 'production';
      }
    }
  };

  Env.config['zaprinta-es'] = JSON.parse(JSON.stringify(Env.config.zaprinta));
  Env.config['zaprinta-es'].serverUrl = 'https://zaprinta-es.compuzz.com/';
  Env.config['zaprinta-es'].compuzzEnv = 'zaprinta-es';
  Env.config['zaprinta-es'].config.customerDefaultMailLanguage = 'ES';
  Env.config['zaprinta-it'] = JSON.parse(JSON.stringify(Env.config.zaprinta));
  Env.config['zaprinta-it'].serverUrl = 'https://zaprinta-it.compuzz.com/';
  Env.config['zaprinta-it'].compuzzEnv = 'zaprinta-it';
  Env.config['zaprinta-it'].config.customerDefaultMailLanguage = 'IT';

  module.exports = Env;
});

